import * as React from "react"
import Layout from "../components/layout"
import '../style.css'
import Star from "../components/star";
import Tabs from "../components/tabs";
import DownloadButton from "../components/downloadButton";
import InternalButton from "../components/internalButton";
import ExternalButton from "../components/externalButton";

const Resources = () => {

    const folder = '/downloads/resources'

    const online = [
        {
            title:"Black Hole Resource Area",
            content:'Learn about black holes! Watch the Fermi-sponsored PBS show "Monster of the Milky Way", find out the answers to frequently-asked-questions, black hole activities for the classroom and much more.',
            link:'/resources/blackHoleResourceArea',
            image:'whirly-cr.jpg',
            external:false
        },
        {
            title:"Fermi Stained Glass Coloring Book",
            content:'The Fermi Gamma-Ray Space Telescope measures some of the highest energy bursts of light in the universe; watching the sky to help scientists answer all sorts of questions about some of the most powerful objects in the universe. This set of coloring pages are based on stained glass-inspired depictions of Fermi and its science.',
            link:'https://imagine.gsfc.nasa.gov/features/coloring_pages/fermi/',
            image:'fermi-stained1.png',
            external:true
        },
        {
            title:"NASA’s Fermi, Swift Capture Revolutionary Gamma-Ray Burst",
            content:'On Dec. 11, 2021, NASA’s Neil Gehrels Swift Observatory and Fermi Gamma-ray Space Telescope detected a blast of high-energy light from the outskirts of a galaxy around 1 billion light-years away. The event has rattled scientists’ understanding of gamma-ray bursts (GRBs), the most powerful events in the universe. This burst is called GRB 211211A.',
            link:'https://svs.gsfc.nasa.gov/14255',
            image:'grbapt.jpg',
            external:true
        },
        {
            title:"Active Galaxies Pop-up Book",
            content:'This short, innovative book for ages 8-12 has three separate activities, including a popup 3D galaxy showing its features, the "Tasty Active Galaxy" activity where kids can make their own edible black hole, and a whimsical story about supermassive black holes called "How the Galaxy Got Its Jets". Although designed for younger children, it can be enjoyed by people of all ages (especially eating the black hole).',
            link:'/resources/activeGalaxyPopUp',
            image:'popupIcon.png',
            external:false
        },
        {
            title:"Fermi Race Card Game",
            content:'Who will be the first to build, launch, and make observations with Fermi?',
            link:'/resources/fermiRaceCarGame',
            image:'fermi-cards.png',
            external:false
        },
        {
            title:"Fermi Art Posters",
            content:'The posters on this page were created by Aurore Simonnet, to illustrate the beauty of the Fermi Gamma-ray Space Telescope and its study of the gamma-ray Universe. To reuse any of these images, please use the credit: NASA/Fermi Gamma-ray Space Telescope/Sonoma State University/Aurore Simonnet.',
            link:'/resources/fermiArtPosters',
            image:"ag.png",
            external:false,
            pdf:false,
        },
        {
            title:"The Fermi/GLAST Cube",
            content:'Images and text from the Fermi/GLAST "magic cube"',
            link:'/resources/fermiGlastCube',
            image:"solarflare.jpg",
            external:false,
            pdf:false,
        },
        {
            title:"Multi-Diverse Activities, Hands-On Sensory Field Trip to Space!",
            content:"This booklet includes a variety of Fermi science-themed activities inspired by the “stained glass” illustrations. The activities are designed to aid in the development of fine motor skills and the stimulation of various senses. Anyone can enjoy these fun activities while learning about the exciting Fermi mission and its study of the gamma-ray sky!",
            link:"/resources/fieldTripToScience",
            image:"FMDivicon24.png",
            external:false,
            pdf:false,
        },
    ];
    const download = [
        {
            title:"Fermi Paper Model",
            content:'This is the final version of a model of Fermi that can be constructed out of paper. The PDF has complete instructions, definitions of Fermi components, and the model itself.',
            link:`${folder}/Fmodel09.pdf`,
            accessible:`${folder}/Fmodel09_508.pdf`,
            image:'fermiPaperModel.png',
            external:false,
            fileType:'PDF',
        },
        {
            title:"Fermi Litho",
            content:"The Fermi litho is a one-page litho that describes the science of NASA's Fermi mission, as well as providing an activity for students to do on the back.",
            link:`${folder}/FermiLitho09pt.pdf`,
            image:"sat2.png",
            external:false,
            fileType:'PDF',
            accessible:`${folder}/FermiLitho09pt_508.pdf`
        },
        {
            title:"Fermi Fact Sheet",
            content:"The Fermi fact sheet is a four-page color brochure that describes the science of NASA's Fermi mission, as well as providing information on what Fermi has seen four years after launch.",
            link:`${folder}/Fermifactsheet12.pdf`,
            image:"pulsar.png",
            external:false,
            fileType:'PDF',
            accessible:`${folder}/Fermifactsheet12_508.pdf`
        },
        {
            title:"Fermi/GLAST Media Guide",
            content:"Science writer's guide to everything you ever wanted to know about Fermi and gamma-ray astronomy.",
            link:`${folder}/GLAST11_508.pdf`,
            image:"grb.png",
            external:false,
            fileType:'PDF',
        },
        {
            title:"Fermi/GLAST public brochure",
            content:`The Fermi/GLAST public brochure was created for the launch in 2008. It highlights the science that Fermi was expected to do pre-launch.`,
            link:`${folder}/glastbroch08.pdf`,
            image:"sat.png",
            external:false,
            fileType:'PDF',
        },
        {
            title:"Five Years of Fermi",
            content:`Five Years of Fermi is a conference poster featuring highlights of the first five years of communications and outreach efforts led by Sonoma State University. The poster was designed by Aurore Simonnet.`,
            link:`${folder}/fiveyears.pdf`,
            image:"sat3.png",
            external:false,
            fileType:'PDF',
        },
        {
            title:"Fermi's Gamma-ray Cosmos",
            content:`Fermi's Gamma-ray Cosmos features the six-year all sky map created from Fermi LAT data as well as some important discoveries to date. The poster was designed by Aurore Simonnet.`,
            link:`${folder}/gammaray.pdf`,
            image:"gammaRayCosmos.png",
            external:false,
            fileType:'PDF',
        },
        {
            title:"Fermi's Decade of Gamma Ray Discoveries",
            content:`Fermi's Decade of Discoveries features the 10-year all sky map created from Fermi LAT data as well as the top 10 discoveries during the first decade of operations. The poster was designed by Aurore Simonnet.`,
            link:`${folder}/decade.pdf`,
            image:"decade.png",
            external:false,
            fileType:'PDF',
        },
        {
            title:"Fermi On Google Sky",
            content:"A virtual tour of Fermi in Google Sky (Download/install of Google Earth required to view file).",
            link:`${folder}/fermiSkyMap.kml`,
            image:"sat4.png",
            external:false,
            fileType:'KML',
        },
    ];

    const resourceTabs = [
        {
            tabName:'Online',
            content:
                online.map( (r,index) =>
                    <div className="tab-item-container" key={index}>
                        <img alt='placeholder'src={`/${r.image}`} className='image-styles'/>
                        <h4>{r.title}</h4>
                        <p>{r.content}</p>
                        {r.external? <ExternalButton link={r.link}/> : <InternalButton link={r.link}/>}
                    </div>    
                )
        },
        {
            tabName:'Downloads',
            content:
                download.map( (r,index) =>
                    <div className="tab-item-container" key={index}>
                        <img alt='placeholder'src={`/${r.image}`} className='image-styles'/>
                        <h4>{r.title}</h4>
                        <p>{r.content}</p>
                        <DownloadButton text = {`Download ${r.fileType}`} link ={r.link}/>
                        {r.accessible? <DownloadButton text = {`Download Acessible ${r.fileType}`} link ={r.accessible}/> : null}
                    </div>    
                )
        }
    ]

    return (
    <Layout>
        <div>
            <h1>RESOURCES</h1>
            <Star />
            <div className="media-items-container">
                <Tabs tabContents={resourceTabs}/>
            </div>
        </div>   
    </Layout>
    )
}

export default Resources